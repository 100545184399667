// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-category-js": () => import("/home/sahand/sadafsabz-no-wp/src/templates/productCategory.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-js": () => import("/home/sahand/sadafsabz-no-wp/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-history-js": () => import("/home/sahand/sadafsabz-no-wp/src/templates/history.js" /* webpackChunkName: "component---src-templates-history-js" */),
  "component---src-pages-404-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-articles-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-certificates-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-contactus-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laboratory-js": () => import("/home/sahand/sadafsabz-no-wp/src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */)
}

