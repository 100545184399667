import { ROUTE_CHANGE } from "../actions/types"

const initialState = {
  current_page: "home",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_CHANGE:
      return { ...state, current_page: action.payload }
    default:
      return state
  }
}
