import { LANGUAGE_CHANGE } from "../actions/types"

const initialState = {
  lang: "en",
  dir: "ltr",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_CHANGE:
      let dir = "ltr"
      if (action.payload === "fa") {
        dir = "rtl"
      }
      return { ...state, dir, lang: action.payload }
    default:
      return state
  }
}
