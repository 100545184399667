module.exports = [{
      plugin: require('/home/sahand/sadafsabz-no-wp/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149302034-1"},
    },{
      plugin: require('/home/sahand/sadafsabz-no-wp/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/home/sahand/sadafsabz-no-wp/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
