import { PRODUCT_CHANGE } from "../actions/types"

const initialState = {
  current_product: { index: 0, productName: "Pistachio" },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_CHANGE:
      return { ...state, current_product: action.payload }
    default:
      return state
  }
}
