import { combineReducers } from "redux"
import languageReducer from "./languageReducer"
import currentPageReducer from "./currentPageReducer"
import currentProductReducer from "./currentProductReducer"

export default combineReducers({
  language: languageReducer,
  current_page: currentPageReducer,
  current_product: currentProductReducer,
})
